<template>
    <div class="recon-summary__container">
        <recon-summary-item
            v-for="(summaryItem, summaryItemIndex) in summaryList"
            :key="summaryItemIndex"
            :summaryItem="summaryItem"
        />
    </div>
</template>

<script>
/** Компонент сводных данных */
import ReconSummaryItem from './ReconSummaryItem.vue';
export default {
    name: 'ReconSummary',
    components: {ReconSummaryItem},
    /**
     * Входные данные компонента
     * @property {Array} summaryList - список элементов сводных данных
     */
    props: {
        summaryList: {
            type: Array,
            default: () => ([])
        }
    }
}
</script>

<style lang="scss" scoped>
    .recon-summary {
        &__container {
            display: flex;
            margin-bottom: 30px;
        }
    }
</style>