<template>
    <div class="recon-summary-item-mix">
        <div class="recon-summary-item-mix__pie">
            <pie
                :size="70"
                :strokeWidth="5"
                :textSize="16" 
                :strokeColor="this.titleColor"
                :total="parseInt(total)"
                :current="parseInt(count)"
                :percentColor="percentColor"
            />
        </div>
        <div class="recon-summary-item-mix__info">
            <div 
                :style="getStyleTitle()"
                class="recon-summary-item-mix__title"
            >
                {{title}}
            </div>
            <div v-if="!isEmpty">
                <div class="recon-summary-item-mix__sub-title">
                    {{subTitle}}
                </div>
                <div class="recon-summary-item-mix__sum">
                    {{sumFormatted}}
                </div>
                <div class="recon-summary-item-mix__count">
                    {{countFormatted}}
                </div>
            </div>
            <div v-if="isEmpty" class="recon-summary-item-mix__info_empty">
                <div>{{emptyMessageFirst}}</div>
                <div>{{emptyMessageLast}}</div>
            </div>
        </div>
    </div>
</template>

<script>
/** Компонент для составного элемента сводных данных */
import MixinReconSummaryItem from '@/mixins/MixinsReconciliation/MixinReconSummaryItem';
import Pie from "@/components/Charts/Pie.vue";
export default {
    name: 'ReconSummaryItemMix',
    components: {Pie},
    mixins: [MixinReconSummaryItem],
    /**
     * Входные данные компонента
     * @property {String} titleColor - цвет заголовка
     * @property {String} subTitle - подзаголовок
     * @property {String} emptyMessageFirst - первое сообщение при пустом контенте
     * @property {String} emptyMessageFirst - второе сообщение при пустом контенте
     * @property {Boolean} isEmptyMessage - есть ли сообщение для пустого контента
     * @property {Number} total - всего сторон
     */
    props: {
        titleColor: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        emptyMessageFirst: {
            type: String,
            default: ''
        },
        emptyMessageLast: {
            type: String,
            default: ''
        },
        isEmptyMessage: {
            type: Boolean,
            default: false
        },
        total: {
            type: Number,
            default: 0
        }
    },
    computed: {
        /**
         * Условие рендера блока с пустым контентом
         * @returns {Boolean}
         */
        isEmpty() {
            return (this.isEmptyMessage && this.count === 0);
        },
        /**
         * Цвет для процента
         * @returns {String}
         */
        percentColor() {
            return this.isEmpty ? '#6EC87A' : '#000000';
        }
    },
    methods: {
        /**
         * Получить стили заголовка
         * @returns {String}
         */
        getStyleTitle() {
            return {color: !this.isEmpty ? String(this.titleColor) : '#000000'};
        }
    }
}
</script>

<style lang="scss" scoped>
    $font-size-small: 10px;
    $font-size-big: 16px;
    .recon-summary-item-mix {
        width: 250px;
        display: flex;
        align-items: center;
        &__info {
            &_empty {
                height: 51px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                color: #6EC87A;
            }
        }
        &__pie {
            display: flex;
            margin-right: 15px;
        }
        &__title {
            margin-bottom: 3px;
        }
        &__sub-title {
            font-size: $font-size-small;
            margin-bottom: 3px;
        }
        &__sum {
            font-size: $font-size-big;
            margin-bottom: 3px;
        }
        &__count {
            font-size: $font-size-small;
            color: #ADB0BB;
        }
    }
</style>