<template>
    <svg 
        :width="size" 
        :height="size"
        class="pie__svg"
    >
        <circle 
            :cx="cx" 
            :cy="cy" 
            :r="radius" 
            :style="styleCircleBase"
        />
        <circle 
            :cx="cx" 
            :cy="cy" 
            :r="radius" 
            :style="styleCircle"
        >
            <animate
                v-if="isAnimation"
                attributeName="stroke-dashoffset" 
                values="360;0" 
                dur="1s" 
                repeatCount="indefinite"
            />
        </circle>
        <text 
            :transform="transformRotatePercent"
            :style="stylePercent"
            :fill="percentColor"
            text-anchor="middle" 
            dominant-baseline="middle" 
            x="50%" 
            y="50%"
        >
            {{percentView}}
        </text>
    </svg>
</template>

<script>
/** Компонент для пирога */
export default {
    name: 'Pie',
    /**
     * Входные данные компонента
     * @property {Number} size - размеры пирога
     * @property {Number} strokeWidth - размер шклаы заполнения
     * @property {Number} textSize - размер текста процента
     * @property {String} strokeColor - цвет шкалы заполнения
     * @property {Number} total - всего
     * @property {Number} current - текущее значение
     * @property {String} percentColor - цвет для процента
     */
    props: {
        size: {
            type: Number,
            default: 70
        },
        strokeWidth: {
            type: Number,
            default: 5
        },
        textSize: {
            type: Number,
            default: 16
        },
        strokeColor: {
            type: String,
            default: '#000000'
        },
        total: {
            type: Number,
            default: 100
        },
        current: {
            type: Number,
            default: 50
        },
        percentColor: {
            type: String,
            default: '#000000'
        }
    },
    /**
     * Данные компонента
     * @property {Boolen} isAnimation - условие рендера анимации
     * @property {Object} timerId - идентификатор таймера
     */
    data: () => ({
        isAnimation: false,
        timerId: null
    }),
    computed: {
        /**
         * Всего в нужном формате
         * @returns {Number}
         */
        totalFormatted() {
            return this.total === 0 ? 1 : this.total;
        },
        /**
         * x центра окружности
         * @returns {Number}
         */
        cx() {
            return Math.ceil(parseInt(this.size) / 2);
        },
        /**
         * y центра окружности
         * @returns {Number}
         */
        cy() {
            return Math.ceil(parseInt(this.size) / 2);
        },
        /**
         * Радиус окружности
         * @returns {Number}
         */
        radius() {
            return Math.ceil(parseInt(this.size) / 2) - parseInt(this.strokeWidth);
        },
        /**
         * Процент
         * @returns {Number}
         */
        percent() {
            return Math.ceil((parseInt(this.current) / parseInt(this.totalFormatted)) * 100);
        },
        /**
         * Процент для отображения
         * @returns {String}
         */
        percentView() {
            return `${this.percent}%`;
        },
        /**
         * Длина окружности
         * @returns {Number}
         */
        circumference() {
            return 2 * this.radius * Math.PI;
        },
        /**
         * Длина для отрисовки
         * @returns {Number}
         */
        drawLength() {
            return Math.ceil(this.percent * this.circumference / 100);
        },
        /**
         * Аттрибут stroke-вasharray
         * @returns {String}
         */
        strokeDasharray() {
            return `${this.drawLength} 999`; 
        },
        /**
         * Базовые стили для окружности
         * @returns {Object}
         */
        styleCircleBase() {
            const styleCircleBase = {
                'fill': 'none',
                'stroke-width': `${this.strokeWidth}px`,
                'stroke-dasharray': '999 999',
                'stroke': '#FFFFFF',
            };
            return styleCircleBase;
        },
        /**
         * Стили для окружности
         * @returns {Object}
         */
        styleCircle() {
            const styleCircle = {
                ...this.styleCircleBase,
                'stroke-dasharray': this.strokeDasharray,
                'stroke': this.strokeColor,
            };
            return styleCircle;
        },
        /**
         * Стили для отображения процента
         * @returns {Object}
         */
        stylePercent() {
            const stylePercent = {'font-size': `${this.textSize}px`};
            return stylePercent;
        },
        /**
         * Поворот для текста с процентов=м
         * @returns {String}
         */
        transformRotatePercent() {
            return `rotate(90, ${this.cx}, ${this.cy})`;
        }
    },
    mounted() {
        /** Запускаем аннимацию */
        this.startAnimation();
    },
    watch: {
        /** Запускаем аннимацию */
        current() {
            this.startAnimation();
        }
    },
    methods: {
        /** Запуск аннимации */
        startAnimation() {
            if (this.timerId === null) {
                this.isAnimation = true;
                this.timerId = setTimeout(() => {
                    this.isAnimation = false;
                    this.timerId = null;
                }, 750);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .pie {
        &__svg {
            transform: rotate(-90deg);
        }
    }
</style>