<template>
        <div
            v-if="fuckingAuth"
            class="page-recon-edit"
        >
            <transition name="page-recon-edit__loading">
                <div v-if="isLoadingFilter" class="page-recon-edit__loading">
                    <base-preloader-gif :size="40" />
                </div>
            </transition>
            <recon-summary :summaryList="summaryInfo"/>
            <navigation-panel
                :items="tabs"
                :selected="tabCurrent"
                @choose="onSelectTab"
                class="page-recon-edit__nav"
            />
            <reconciliation-edit-control
                :key="renderKey"
                :countTotal="countTotalCurrent"
                :countSelected="countSelectedCurrent"
                :currentSelectOptions="currentSelectOptions"
                :dataTypes="dataTypes"
                class="page-recon-edit__control"
                @onApply="onApply"
            />
                <reconciliation-edit-table
                    :key="renderKey + 1"
                    :columns="columns"
                    :rows="rows"
                    :rowsStyles="rowsStyles"
                    :isLoading="isLoading"
                    :currentSelectOptions="currentSelectOptions"
                    class="page-recon-edit__table"
                    @filter="onFilter"
                    @sort="onSort"
                    @onLazyload="onLazyload"
                    @onEditRow="onEditRow"
                    @onCheckRow="onCheckRow"
                    @onCheckRowsAll="onCheckRowsAll"
                />
        </div>
        <div v-else class="page-recon-edit__container">
            <layout-reconciliation-auth @onFuckingAuth="onAuth"/>
        </div>
</template>

<script>
/** Компонент для страницы редактирования сверок */
import BasePreloaderGif from "@/components/Base/BasePreloaderGif.vue";
import LayoutReconciliationAuth from '@/layouts/LayoutReconciliationAuth';
import ReconSummary from '@/components/Reconciliation/ReconSummary/ReconSummary';
import ReconciliationEditTable from '@/components/Reconciliation/ReconciliationEdit/ReconciliationEditTable';
import ReconciliationEditControl from '@/components/Reconciliation/ReconciliationEdit/ReconciliationEditControl';
import NavigationPanel from '@/components/NavigationPanel';
import ServiceReconciliationEdit from "@/services/ServicesReconciliation/ServiceReconciliationEdit";
import MixinReconciliationAuth from "@/mixins/MixinsReconciliation/MixinReconciliationAuth";
import {mapState} from "vuex";
export default {
    name: 'PageReconciliationEdit',
    components: {
        LayoutReconciliationAuth,
        ReconSummary,
        ReconciliationEditTable,
        ReconciliationEditControl,
        NavigationPanel,
        BasePreloaderGif
    },
    mixins: [MixinReconciliationAuth],
    /**
     * Данные компонента
     * @property {Number} pageSize - размер страницы
     * @property {Number} pageNumber - номер текущие страницы
     * @property {Array} columns - колонки
     * @property {Array} rows - строки
     * @property {String} tabCurrent - текущий таб
     * @property {Object} counter - колличество сторон
     * @property {Boolean} isLoading - условие для отображения прелоадера
     * @property {Boolean} isLoadingFilter - условие для прелоадера при фильтрации
     * @property {Object} tableSort - данные сортировки таблицы
     * @property {Array} tableFilter - данные фильтра таблицы
     */
    data: () => ({
        renderKey: 0,
        pageSize: 100,
        pageNumber: 0,
        columns: [],
        rows: [],
        currentSelectOptions: {},
        rowsIdChecked: [],
        tabCurrent: 'revised',
        counter: {
            countFound: '0',
            sumFound: '0',
            countNotFound: '0',
            sumNotFound: '0',
            countRevised: '0',
            sumRevised: '0',
            countNotRevised: '0',
            sumNotRevised: '0'
        },
        isLoading: false,
        isLoadingFilter: false,
        tableSort: {},
        tableFilter: [],
    }),
    computed: {
        /** Проксируем состояния из стора */
        ...mapState({
            filterSelectedData: state => state.filter.filterSelectedData,
            isFilterLoading: state => state.filter.isFilterLoading
        }),
        /**
         * Объект пагинации
         * @returns {Object}
         */
        pagination() {
            const pagination = {
                pageSize: this.pageSize,
                pageNumber: this.pageNumber
            };
            return pagination;
        },
        /**
         * Стили для строк
         * @returns {Object}
         */
        rowsStyles() {
            const rowsStyles = this.rows.reduce((rowsStyles, row) => {
                const {id = '-1'} = row;
                let rowStyle = {background: this.getColorRow(row)};
                return {...rowsStyles, [id]: rowStyle};
            }, {});
            return rowsStyles;
        },
        /**
         * Табличные табы
         * @returns {Array}
         */
        tabs() {
            const {countRevised = '0', countNotRevised = '0', countNotFound = ''} = this.counter;
            const tabs = [
                {
                    id: 'revised',
                    title: 'Сверенные',
                    postscript: countRevised,
                },
                {
                    id: 'notRevised',
                    title: 'Не сверенные',
                    postscript: countNotRevised,
                },
                {
                    id: 'notFound',
                    title: 'Не созданные',
                    postscript: countNotFound,
                }
            ];
            return tabs;
        },
        /**
         * Информация сводных данных
         * @returns {Object}
         */
        summaryInfo() {
            const {
                countRevised = '0',
                sumRevised = '0',
                countNotRevised = '0',
                sumNotRevised = '0',
                countNotFound = '0',
                sumNotFound = '0',
                countInstall = '0',
                sumInstall = '0',
                countPrint = '0',
                sumPrint = '0'
            } = this.counter;
            const summaryInfo = [
                {
                    id: 'revised',
                    type: 'mix',
                    title: 'Сверено сторон',
                    titleColor: '#6EC87A',
                    subTitle: 'Аренда себестоимость',
                    count: countRevised,
                    sum: sumRevised,
                    total: parseInt(countRevised) + parseInt(countNotRevised)
                },
                {
                    id: 'notRevised',
                    type: 'mix',
                    title: 'Не сверено сторон',
                    titleColor: '#F84967',
                    subTitle: 'Аренда себестоимость',
                    emptyMessageFirst: 'Таких сторон нет.',
                    emptyMessageLast: 'Все отлично!',
                    isEmptyMessage: true,
                    count: countNotRevised,
                    sum: sumNotRevised,
                    total: parseInt(countRevised) + parseInt(countNotRevised)
                },
                {
                    id: 'notFound',
                    type: 'mix',
                    title: 'Не создано сторон',
                    titleColor: '#FFB300',
                    subTitle: 'Аренда себестоимость',
                    emptyMessageFirst: 'Таких сторон нет.',
                    emptyMessageLast: 'Все отлично!',
                    isEmptyMessage: true,
                    count: countNotFound,
                    sum: sumNotFound,
                    total: parseInt(countRevised) + parseInt(countNotRevised)
                },
                {
                    id: 'install',
                    type: 'base',
                    title: 'Монтаж',
                    count: countInstall,
                    sum: sumInstall
                },
                {
                    id: 'print',
                    type: 'base',
                    title: 'Печать',
                    count: countPrint,
                    sum: sumPrint
                },
            ];
            return summaryInfo;
        },
        /**
         * Всего текущего таба
         * @returns {Number}
         */
        countTotalCurrent() {
            const tabCurrent = this.tabs.find(tab => {
                const {id = ''} = tab;
                return String(this.tabCurrent) === String(id);
            }) || {};
            const {postscript = 0} = tabCurrent;
            return Number(postscript);
        },
        /**
         * Выбрано в текущем табе
         * @returns {Number}
         */
        countSelectedCurrent() {
            return this.rowsIdChecked.length;
        },
        /**
         * Типы данных для массового редактирования
         * @returns {Array}
         */
        dataTypes() {
            const dataTypes = this.columns.filter(column => {
                const {isSlot = false, propExtra = ''} = column;
                return isSlot && (propExtra === 'entity' || propExtra === 'contractor');

            }).map(column => {
                const {prop = '', propGeneral = '', propExtra = '', label = ''} = column;
                const dataType = {
                    id: String(prop),
                    value: String(label),
                    propGeneral: String(propGeneral),
                    propExtra: String(propExtra)
                };
                return dataType;
            });
            return dataTypes;
        }
    },
    watch: {
        /** Следим за изменением фильтра и подтягиваем найдено */
        filterSelectedData() {
            /** При монтировании грузим таблицу и колличество*/
            if (this.fuckingAuth) {
                this.getTable();
                this.getCounters();
            }
        },
        /** Следим за инициализацией смены фильтра */
        isFilterLoading() {
            this.isLoadingFilter = true;
        }
    },
    mounted() {
        /** При монтировании грузим таблицу и колличество*/
        if (this.fuckingAuth) {
            this.isLoadingFilter = true;
            this.getTable();
            this.getCounters();
            this.onGetOptions();
        }
    },
    methods: {
        onAuth() {
            this.onFuckingAuth();
            this.getTable();
            this.getCounters();
        },
        /**
         * Выбор таба
         * @param {String} id - идентификатор
         */
        onSelectTab(id = '') {
            this.tableSort = {};
            this.tableFilter = [];
            this.renderKey += 1;
            this.rowsIdChecked = [];
            this.tabCurrent = String(id);
            this.getTable();
        },
        /**
         * Событие ленивой загрузки
         */
        onLazyload() {
            this.pageNumber += 1;
            this.getTableLazyload();
        },
        /**
         * Событие редактирования строки
         * @param {Array} editData - данные рдактирования
         */
        onEditRow(editData = []) {
            const rowsIdsEdit = editData.map(editItem => {
                const {rowId = '-1'} = editItem;
                return String(rowId);
            });
            const oldRows = this.rows.filter(row => {
                const {id = '-1'} = row;
                return rowsIdsEdit.includes(String(id));
            });
            let date = this.filterSelectedData.date;
            ServiceReconciliationEdit.editRow(this.tabCurrent, oldRows, date, editData, (dataEdit) => {
                let updateCounters = false;
                rowsIdsEdit.forEach(rowId => {
                    const indexRowEdit = dataEdit.findIndex(row => {
                        const {id = '-1'} = row;
                        return String(id) === String(rowId);
                    });
                    const indexRowOld = this.rows.findIndex(row => {
                        const {id = '-1'} = row;
                        return String(id) === String(rowId);
                    });
                    if(indexRowEdit === -1){
                        updateCounters = true;
                        this.rows.splice(indexRowOld, 1);
                    }
                    else {
                        this.rows.splice(indexRowOld, 1, dataEdit[indexRowEdit]);
                    }
                })
                if(updateCounters){
                    this.getCounters();
                }
            });
        },
        /**
         * Получение значений для выпадающего списка
         */
        onGetOptions(){
            ServiceReconciliationEdit.getContractorEntities((data) => {
                this.currentSelectOptions = data;
            });
        },
        /**
         * Выбор строки
         * @param {Object} checkData - данные выбора
         */
        onCheckRow(checkData = {}) {
            const {row = {}, rowIsSelected = false} = checkData;
            const {id = '-1'} = row;
            if (rowIsSelected) {
                this.rowsIdChecked.push(String(id));
            }
            else {
                const index = this.rowsIdChecked.findIndex(rowId => String(rowId) === String(id));
                if (index !== -1) {
                    this.rowsIdChecked.splice(index, 1);
                }
            }
        },
        /**
         * Выбор всех строк
         * @param {Object} isCheck - выбрана ли строка
         */
        onCheckRowsAll(isCheck = false) {
            if (isCheck) {
                this.rowsIdChecked = this.rows.map(row => {
                    const {id = '-1'} = row;
                    return String(id);
                });
            }
            else
                this.rowsIdChecked = [];
        },
        /**
         * Применить массовое редактирование
         * @param {Object} dataTypeChecked - выбранные типы данных
         * @param {String} newValue - новое значение
         */
        onApply(dataTypeChecked = {}, newValue = '') {
            const {propGeneral = '', propExtra = ''} = dataTypeChecked;
            const editData = this.rowsIdChecked.map(rowId => {
                const editDataItem = {
                    rowId: String(rowId),
                    propGeneral: String(propGeneral),
                    propExtra: String(propExtra),
                    newValue: String(newValue)
                };
                return editDataItem;
            });
            this.onEditRow(editData);
        },
        /**
         * Получить колличество сторон
         */
        getCounters() {
            this.isLoadingFilter = true;
            ServiceReconciliationEdit.getCounters((dataCounters) => {
                this.getCountersAfter(dataCounters);
                this.isLoadingFilter = false;
            });
        },
        /**
         * Запрос на получение таблицы
         */
        getTable() {
            this.pageNumber = 0;
            this.isLoading = true;
            ServiceReconciliationEdit.getTable(this.tabCurrent, this.pagination, this.tableFilter, this.tableSort, (columns, rows) => {
                this.getTableAfter(columns, rows);
                this.isLoading = false;
            });
        },
        /**
         * Запрос на получение новой пачки строк при ленивой загрузке
         */
        getTableLazyload() {
            this.isLoading = true;
            ServiceReconciliationEdit.getTable(this.tabCurrent, this.pagination, this.tableFilter, this.tableSort, (_, rows) => {
                this.getTableLazyloadAfter(rows);
                this.isLoading = false;
            });
        },
        /**
         * Событие фильтрации по столбцам
         * @param {Object} dataFilter - данные фильтра по столбцам
         */
        onFilter(dataFilter = {}) {
            this.beforeFilterTable(dataFilter);
            this.pageNumber = 0;
            this.isLoading = true;
            ServiceReconciliationEdit.getTable(this.tabCurrent, this.pagination,this.tableFilter, this.tableSort, (columns, rows) => {
                this.getTableAfter(columns, rows);
                this.isLoading = false;
            });
        },
        /**
         * Событие сортировки
         * @param {Object} dataSort - данные сортировки
         */
        onSort(dataSort = {}) {
            this.beforeSortTable(dataSort);
            this.pageNumber = 0;
            this.isLoading = true;
            ServiceReconciliationEdit.getTable(this.tabCurrent, this.pagination,this.tableFilter, this.tableSort, (columns, rows) => {
                this.getTableAfter(columns, rows);
                this.isLoading = false;
            });
        },
        /**
         * Событие, сработающее перед фильтрацией по столбцам
         * @param {Object} dataFilter - данные фильтра по столбцам
         */
        beforeFilterTable(dataFilter = {}) {
            const filter = Object.keys(dataFilter).map(columnKey => {
                const filterItem = {
                    column: columnKey,
                    values: [dataFilter[columnKey]]
                };
                return filterItem;
            });
            this.tableFilter = filter;
        },
        /**
         * Событие, сработающее перед сортировкой
         * @param {Object} dataSort - данные сортировки
         */
        beforeSortTable(dataSort = {}) {
            const {type = '', key = ''} = dataSort;
            this.tableSort = {column: key, type};
        },
        /**
         * Событие, сработающее после получения колличества сторон
         * @param {Object} dataCounters - данные колличества сторон
         */
        getCountersAfter(dataCounters = {}) {
            const {
                countFound = '',
                sumFound = '',
                countNotFound = '',
                sumNotFound = '',
                countRevised = '',
                sumRevised = '',
                countNotRevised = '',
                sumNotRevised = '',
                countAdditionalInstall = '',
                sumAdditionalInstall = '',
                countAdditionalPrint = '',
                sumAdditionalPrint = '',
                countInstall = '',
                sumInstall = '',
                countPrint = '',
                sumPrint = ''
            } = dataCounters;
            this.counter = {
                countFound,
                sumFound,
                countNotFound,
                sumNotFound,
                countRevised,
                sumRevised,
                countNotRevised,
                sumNotRevised,
                countAdditionalInstall,
                sumAdditionalInstall,
                countAdditionalPrint,
                sumAdditionalPrint,
                countInstall,
                sumInstall,
                countPrint,
                sumPrint
            };
        },
        /**
         * Событие, сработающее после получения таблицы
         * @param {Array} columns - колонки
         * @param {Array} rows - строки
         */
        getTableAfter(columns = [], rows = []) {
            this.columns = columns;
            this.rows = rows;
        },
        /**
         * Событие, сработающее после получения новой пачки строк
         * @param {Array} rows - строки
         */
        getTableLazyloadAfter(rows = []) {
            this.rows.push(...rows);
        },
        /**
         * Получить цвет строки
         * @param {Object} row - строка
         * @returns {String}
         */
        getColorRow(row = {}) {
            let color = '';
            if (this.tabCurrent === 'notRevised') {
                const isReviseDifference = !this.isNotReviseDifference(row);
                color = isReviseDifference ? '#FFEFEF' : '#F4F5F6';
            }
            else
                color = this.tabCurrent === 'revised' ? '#EAF8F1' : '#FFF8E8';
            return color;
        },
        /**
         * Есть ли двойная цена
         * @param {Object} row - строка
         * @returns {Boolean}
         */
        isNotReviseDifference(row = {}) {
            const isReviseDifference = this.columns.every(column => {
                const {propGeneral = '', propExtra = ''} = column;
                const subColumns = row[propGeneral] || {};
                const {status = ''} = subColumns[propExtra] || {};
                return status !== 'reviseDifference';
            });
            return isReviseDifference;
        }
    }
}
</script>

<style lang="scss" scoped>
    .page-recon-edit {
        height: 100%;
        display: flex;
        flex-direction: column;
        // position: relative;
        &__loading {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 99999;
            background: #fff;
            padding: 0px 40px 40px 40px;
            &-enter-active, &-leave-active {
                transition: opacity 300ms;
            }
            &-enter, &-leave-to {
                opacity: 0;
            }
        }
        &__container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
        &__table {
            // margin-top: 20px;
            // padding-top: 90px !important;
            &-view {
                position: relative;
                height: 70%;
            }
            &-wrapper {
                height: 100%;
            }
        }
        &__nav {
            margin-bottom: 32px;
        }
        &__summary {
            margin-bottom: 32px;
        }
        &__control {
            margin-bottom: 15px;
        }
    }
</style>
