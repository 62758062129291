<template>
    <div class="recon-edit-control">
        <div class="recon-edit-control__item">
            <div class="recon-edit-control__count">
                Выбрано {{countSelected}} из {{countTotal}}
            </div>
        </div>
        <div class="recon-edit-control__item">
            <base-select
                :isFixed="true"
                :isVirtualScroll="true"
                :options="dataTypes"
                :value="dataTypeCheckedId"
                defaultTitle="Выберите тип данных"
                class="recon-edit-control__select"
                @check="onCheckType"
            />
        </div>
        <div class="recon-edit-control__item">
            <base-select
                v-if="Object.keys(dataTypeChecked).length"
                :isFixed="true"
                :isVirtualScroll="true"
                :options="getCurrentOptions"
                :value="dataTypeEditValue.id"
                :canSearch="true"
                :open="true"
                @check="onInputType"
            />
<!--            <base-text-input-->
<!--                :value="dataTypeEditValue"-->
<!--                view="base"-->
<!--                class="recon-edit-control__input"-->
<!--                @onInputLazy="onInputType"-->
<!--            />-->
        </div>
        <div class="recon-edit-control__item">
            <base-button
                view="secondary"
                :disabled="!isApply"
                @click="onApply"
            >
                Применить
            </base-button>
        </div>
    </div>
</template>

<script>
/** Компонент панели массового редактирования сверок */
import BaseSelect from "@/components/Base/BaseSelect";
// import BaseTextInput from '@/components/Base/BaseTextInput';
import BaseButton from "@/components/Base/BaseButton";
export default {
    name: 'ReconciliationEditControl',
    components: {
        BaseSelect,
        // BaseTextInput,
        BaseButton
    },
    /**
     * Входные данные компонента
     * @property {Number} countTotal - колличество всего
     * @property {Number} countSelected - колличество выбранно
     * @property {Array} dataTypes - типы данных
     */
    props: {
        countTotal: {
            type: Number,
            default: 0
        },
        countSelected: {
            type: Number,
            default: 0
        },
        dataTypes: {
            type: Array,
            default: () => ([])
        },
        currentSelectOptions: {
            type: Object,
            default: () => ({})
        },
    },
    /**
     * Данные компонента
     * @property {Object} dataTypeChecked - выбранный тип данных
     * @property {String} dataTypeEditValue - новое зачение для типа данных
     */
    data: () => ({
        dataTypeChecked: {},
        dataTypeEditValue: {}
    }),
    computed: {
        /**
         * Идентификаторы выбранного типа данных
         * @returns {String}
         */
        dataTypeCheckedId() {
            const {id = ''} = this.dataTypeChecked;
            return String(id);
        },
        /**
         * Условие для активности кнопки применить
         * @returns {Boolean}
         */
        isApply() {
            return this.countSelected > 0 && this.dataTypeCheckedId !== '' && this.dataTypeEditValue !== '';
        },
        getCurrentOptions(){
            return this.currentSelectOptions[this.dataTypeChecked.propExtra];
        }
    },
    methods: {
        /**
         * Выбрать тип
         * @param {Object} dataType - тип данных
         */
        onCheckType(dataType = {}) {
            this.dataTypeChecked = dataType;
        },
        /**
         * Ввод значения для типа данных
         * @param {Object} value - значение
         */
        onInputType(value = {}) {
            // console.log(value)
            this.dataTypeEditValue = value;
        },
        /**
         * Применить
         */
        onApply() {
            this.$emit('onApply', this.dataTypeChecked, this.dataTypeEditValue.id);
        }
    }
}
</script>

<style lang="scss" scoped>
    .recon-edit-control {
        display: flex;
        align-items: center;
        &__count {
            font-size: 12px;
            font-weight: 700;
            width: 200px;
        }
        &__item {
            margin-right: 20px;
        }
        &__select {
            width: 200px;
        }
    }
</style>
