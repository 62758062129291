import ServiceApi from '../ServiceApi';
/** Сервис для работы со страницей редактирования сверок */
export default class ServiceReconciliationEdit {
    /**
     * Маршурты для запросов
     * @static
     * @property {Object} getTable - маршруты для получения таблицы
     * @property {Object} editTable - маршруты для редактирования таблицы
     * @property {String} getCounters - маршрут для получения колличества
     */
    static requestRouts = {
        getTable: {
            revised: '/reconciliation/table/get-rows/',
            notRevised: '/reconciliation/table/get-desc-rows/',
            notFound: '/reconciliation/table/unmached-get-rows/'
        },
        editTable: {
            revised: '/reconciliation/action/save/',
            notRevised: '/reconciliation/action/save/',
            notFound: '/reconciliation/action/save-backlog'
        },
        getCounters: '/reconciliation/table/counters'
    };
    /**
     * Получить табличные данные
     * @static
     * @async
     * @param {String} type - тип для запроса (revised|notRevised|notFound)
     * @param {Object} pagination - объект пагинации
     * @param {Array} filter - фильтрация
     * @param {Object} sort - сортировка
     * @param {Function} actionAfter - событие, сработающее после получения табличных данных
     */
    static async getTable(type = '', pagination = {}, filter = [], sort = {}, actionAfter = () => {}) {
        const requestRoute = this.requestRouts.getTable[type] || '';
        const postData = this.collectRequestBodyTable(pagination);
        postData.filter = filter;
        postData.sort = sort;
        try {
            const res = await ServiceApi.post('reconciliation', requestRoute, postData);
            const {data = {}} = res;
            const {data: dataTable = {}} = data;
            const {cols = {}, rows = []} = dataTable;
            const columnsAdapted = this.adapterTableColumns(cols);
            actionAfter(columnsAdapted, rows);
        }
        catch(error) {
            console.log(error);
        }
    }
    /**
     * Редактирование табличных данных
     * @static
     * @async
     * @param {String} type - тип для запроса (revised|notRevised|notFound)
     * @param {String} date
     * @param {Array} oldRows - старые строки
     * @param {Array} editData - данные редактирования
     * @param {Function} actionAfter - событие, сработающее после редактирования
     */
    static async editRow(type = '', oldRows = [], date = '', editData = [], actionAfter = () => {}) {
        const requestRoute = this.requestRouts.editTable[type] || '';
        const postData = this.collectRequestBodyEdit(oldRows, editData);
        postData.date = date;
        try {
            const res = await ServiceApi.post('reconciliation', requestRoute, postData);
            const {data = {}} = res;
            const {data: dataEdit = []} = data;
            actionAfter(dataEdit);
        }
        catch(error) {
            console.log(error);
        }
    }
    /**
     * Получение списка юрлиц
     * @static
     * @async
     * @param {Function} actionAfter - событие, сработающее после редактирования
     */
    static async getContractorEntities(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post('reconciliation', '/contractor/default/get-entities');
            const {data = {}} = res;
            const {data: dataEdit = []} = data;
            actionAfter(dataEdit);
        }
        catch(error) {
            console.log(error);
        }
    }
    /**
     * Получить колличество сторон
     * @static
     * @async
     * @param {Function} actionAfter - событие, сработающее после получения колличества
     */
    static async getCounters(actionAfter = () => {}) {
        const requestRoute = this.requestRouts.getCounters || '';
        const postData = this.collectRequestBodyGetCounters();
        try {
            const res = await ServiceApi.post('reconciliation', requestRoute, postData);
            const {data = {}} = res;
            const {data: dataCounters = {}} = data;
            const {
                found = {},
                not_found: notFound = {},
                revised = {},
                not_revised: notRevised = {},
                additional_install: additionalInstall = {},
                additional_print: additionalPrint = {},
                install = {},
                print = {},
            } = dataCounters;
            const {count: countAdditionalInstall = '0', sum: sumAdditionalInstall = '0'} = additionalInstall;
            const {count: countAdditionalPrint = '0', sum: sumAdditionalPrint = '0'} = additionalPrint
            const {count: countInstall = '0', sum: sumInstall = '0'} = install;
            const {count: countPrint = '0', sum: sumPrint = '0'} = print;
            const {count: countFound = '0', sum: sumFound = '0'} = found;
            const {count: countNotFound = '0', sum: sumNotFound = '0'} = notFound;
            const {count: countRevised = '0', sum: sumRevised = '0'} = revised;
            const {count: countNotRevised = '0' , sum: sumNotRevised = '0'} = notRevised;
            const responseData = {
                countFound: String(countFound),
                sumFound: String(sumFound),
                countNotFound: String(countNotFound),
                sumNotFound: String(sumNotFound),
                countRevised: String(countRevised),
                sumRevised: String(sumRevised),
                countNotRevised: String(countNotRevised),
                sumNotRevised: String(sumNotRevised),
                countAdditionalInstall: String(countAdditionalInstall),
                sumAdditionalInstall: String(sumAdditionalInstall),
                countAdditionalPrint: String(countAdditionalPrint),
                sumAdditionalPrint: String(sumAdditionalPrint),
                countInstall: String(countInstall),
                sumInstall: String(sumInstall),
                countPrint: String(countPrint),
                sumPrint: String(sumPrint)
            };
            actionAfter(responseData);
        }
        catch(error) {
            console.log(error);
        }
    }
    /**
     * Собрать тело запроса для редактирования таблицы
     * @static
     * @param {Array} oldRows - старые строки
     * @param {Array} editData - данные редактирования
     * @returns {Object}
     */
    static collectRequestBodyEdit(oldRows = [], editData = []) {
        const type = 1;
        const data = this.collectRequestBodyEditData(oldRows, editData);
        // const date = this.collectRequestBodyEditDate();
        return {type, data};
    }
    /**
     * Собрать строки для тела запроса для редактирования таблицы
     * @static
     * @param {Array} oldRows - старые строки
     * @param {Array} editData - данные редактирования
     * @returns {Object}
     */
    static collectRequestBodyEditData(oldRows = [], editData = []) {
        const oldRowsIds = oldRows.map(oldRow => {
            const {id = '-1'} = oldRow;
            return String(id);
        });
        let data = {};
        oldRowsIds.forEach(id => {
            const editDataObj = editData.find(editItem => {
                const {rowId = '-1'} = editItem;
                return String(rowId) === (id)
            }) || {};
            const {newValue = '', propExtra = '', propGeneral = ''} = editDataObj;
            data = {
                ...data,
                [id]: {
                    [propGeneral]: propExtra !== false
                        ? {
                            [propExtra]: newValue
                        }
                        : newValue
                }
            }
        });
        return data;
    }
    /**
     * Собрать дату для тела запроса для редактирования таблицы
     * @static
     * @returns {String}
     */
    static collectRequestBodyEditDate() {
        const {year = 0, month = 0} = this.getDate();
        const dateFormatted = `${year}-${month}-01`;
        return dateFormatted;
    }
    /**
     * Собрать тело запроса для получения колличества сторон
     * @static
     * @returns {Object}
     */
    static collectRequestBodyGetCounters() {
        const type = 1;
        const {year = 0, month = 0} = this.getDate();
        return {type, year, month};
    }
    /**
     * Собрать тело запроса для получения табличных данных
     * @static
     * @param {Object} pagination - объект пагинации
     * @returns {Object}
     */
    static collectRequestBodyTable(pagination = {}) {
        const {pageSize = 0, pageNumber = 0} = pagination;
        const postData = {
            pagination: {
                per_page: parseInt(pageSize),
                page: parseInt(pageNumber)
            }
        };
        return postData;
    }
    /**
     * Адаптер для колонок
     * @static
     * @param {Object} columns - колонки
     * @returns {Array}
     */
    static adapterTableColumns(columns = {}) {
        const columnsSplitted = Object.keys(columns).reduce((columnsAccumulator, columnKey) => {
            const column = columns[columnKey];
            const {multiple = false} = column;
            const keySplit = multiple ? 'prices' : 'base';
            const newColumnsAccumulator = {
                ...columnsAccumulator,
                [keySplit]: [...columnsAccumulator[keySplit], columnKey]
            }
            return newColumnsAccumulator;
        }, {base: [], prices: []});
        const {base = [], prices = []} = columnsSplitted
        const columnsAdaptedBase = this.adapterTableColumnsBase(base, columns);
        const columnsAdaptedPrice = this.adapterTableColumnsPrice(prices, columns);
        return [...columnsAdaptedBase, ...columnsAdaptedPrice];
    }
    /**
     * Адаптер для базовых колонок
     * @static
     * @param {Array} columnsBase - массив базовых колонок
     * @param {Object} columns - колонки
     * @returns {Array}
     */
    static adapterTableColumnsBase(columnsBase = [], columns) {
        const columnsAdaptedBase = columnsBase.map(columnKey => {
            const column = columns[columnKey];
            const {name = '', filter = false, sort = false, editable = false} = column;
            let columnStyles = {};
            switch (columnKey) {
                case 'address':
                    columnStyles = {width: '300px'};
                    break;
                case 'side':
                    columnStyles = {width: '60px'};
                    break;
                case 'client':
                case 'supplier':
                    columnStyles = {width: '140px'};
                    break;
                default:
                    columnStyles = {width: '120px'};
                    break;
            }
            const columnAdaptedBase = {
                prop: columnKey,
                label: name,
                canSearch: filter,
                canSorted: sort,
                isSlot: editable,
                isMainAttribute: true,
                columnStyles: columnStyles
            };
            return columnAdaptedBase;
        });
        return columnsAdaptedBase;
    }
    /**
     * Адаптер для колонок с ценами
     * @static
     * @param {Array} columnsPrice - колонки с ценами
     * @param {Object} columns - колонки
     * @returns {Array}
     */
    static adapterTableColumnsPrice(columnsPrice, columns) {
        const columnsAdaptedPrice = columnsPrice.reduce((columnsAccumulator, columnKey) => {
            const column = columns[columnKey];
            const {subCols = {}} = column;
            const columnStyles = {width: '130px'};
            const subColsAdapted = Object.keys(subCols).map(subColKey => {
                const subCol = subCols[subColKey];
                const {name = '', filter = false, sort = false, editable = false} = subCol;
                const subColAdapted = {
                    prop: `${columnKey}_${subColKey}`,
                    propGeneral: columnKey,
                    propExtra: subColKey,
                    label: name,
                    isSlot: true,
                    canSearch: filter,
                    canSorted: sort,
                    canEdit: editable,
                    isCheckboxValue: String(subColKey) === 'tax',
                    isPriceValue: String(subColKey) === 'price' || String(subColKey) === 'cost_price',
                    columnStyles: String(subColKey) === 'price' ? {...columnStyles, 'margin-left': '5px'} : columnStyles
                    // columnStyles: String(subColKey) === 'price' ? {...columnStyles, 'border-left': '1px solid #707070'} : columnStyles
                };
                return subColAdapted;
            });
            const newColumnsAccumulator = [...columnsAccumulator, ...subColsAdapted];
            return newColumnsAccumulator;
        }, []);
        return columnsAdaptedPrice;
    }
    /**
     * Получить текущую системную дату
     * @static
     * @returns {Object}
     */
    static getDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        return {year, month};
    }
}
