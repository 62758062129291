<template>
    <app-table
        primaryKey="id"
        :canCheck="true"
        :columns="columns"
        :rows="rows"
        :rowsStyles="rowsStyles"
        :isRowMargin="true"
        :isProgressBar="isLoading"
        @sort="onSort"
        @search="onFilter"
        @lazyload="onLazyload"
        @onCheckRow="onCheckRow"
        @onCheckRowsAll="onCheckRowsAll"
    >
        <template
            v-for="(column, columnIndex) in columnsPrice"
            v-slot:[column]="scopeData"
        >
            <div 
                v-if="scopeData.column.isPriceValue"
                :key="columnIndex"
                class="recon-edit-table__col-price-container"
            >
                <div
                    v-if="!isColumnPriceEdited(scopeData)"
                    :class="getColumnPriceClass(scopeData)"
                    class="recon-edit-table__col-price"
                    @dblclick="onDblclickColumnPrice(scopeData)"
                >
                    <div class="recon-edit-table__col-price-text">
                        {{adapterColumnPrice(getColumnPriceProp(scopeData, keysPriceProps.oldValue))}}
                    </div>
                    <div v-if="isColumnPriceAccept(scopeData, keysPriceProps.oldValue)">
                        <base-checkbox-accept
                            :isAccept="true"
                            iconSize="sm"
                            class="recon-edit-table__col-price-accept"
                            @onCheck="onAcceptPrice($event, scopeData)"
                        />
                    </div>
                </div>
                <div v-if="isColumnPriceEdited(scopeData)">
                    <base-text-input
                        ref="baseTextInput"
                        :value="getPriceEditedValue(scopeData)"
                        @onBlur="onBlurPriceEdited($event, scopeData)"
                    />
                </div>
                <div
                    :class="getColumnPriceClass(scopeData)"
                    class="recon-edit-table__col-price"
                >
                    <div class="recon-edit-table__col-price-text">
                        {{adapterColumnPrice(getColumnPriceProp(scopeData, keysPriceProps.reviseValue))}}
                    </div>
                    <div v-if="isColumnPriceNoAccept(scopeData, keysPriceProps.reviseValue)">
                        <base-checkbox-accept
                            :isAccept="false"
                            iconSize="sm"
                            class="recon-edit-table__col-price-accept"
                            @onCheck="onAcceptPrice($event, scopeData)"
                        />
                    </div>
                </div>
            </div>
            <div
                v-else-if="scopeData.column.propExtra == 'entity' || scopeData.column.propExtra == 'contractor'"
                :key="columnIndex"
            >
                <div
                    v-if="!isColumnPriceEdited(scopeData)"
                    class="recon-edit-table__col-price_string"
                    @dblclick="onDblclickColumnEntity(scopeData)"
                >
                    {{getColumnNoPriceValue(scopeData)}}
                </div>
                <div v-if="isColumnPriceEdited(scopeData)">
                    <BaseSelect
                        :options="currentSelectOptions[scopeData.column.propExtra]"
                        :canSearch="true"
                        :open="true"
                        @check="onCheckSelect($event, scopeData)"
                    />
                </div>
            </div>
            <div v-else-if="scopeData.column.isMainAttribute"
                :key="columnIndex"
            >
                <div
                    v-if="!isColumnPriceEdited(scopeData)"
                    class="recon-edit-table__col-price_string"
                    @dblclick="onDblclickColumnBase(scopeData)"
                >
                    {{scopeData.row[column]}}
                </div>
                <div v-if="isColumnPriceEdited(scopeData)">
                    <base-text-input
                        ref="baseTextInput"
                        :value="scopeData.row[column]"
                        @onBlur="onBlurBaseEdited($event, scopeData)"
                    />
                </div>
            </div>
            <div v-else-if="scopeData.column.isCheckboxValue"
                :key="columnIndex"
            >
                <div
                    class="recon-edit-table__checkbox cell cell-checkbox"
                    v-if="scopeData.column.canEdit"
                >
                    <label>
                        <input
                            type="checkbox"
                            :checked="getColumnPriceProp(scopeData, keysPriceProps.oldValue)"
                            @change="onCheckTax($event, scopeData)"
                        >
                        <span class="checkmark" />
                    </label>
                </div>
                <div
                    v-else
                    class="recon-edit-table__col-price_string"
                >
                    {{getBoolValue(scopeData)}}
                </div>
            </div>
            <div
                v-else
                :key="columnIndex"
                class="recon-edit-table__col-price-container"
            >
                <div
                    v-if="!isColumnPriceEdited(scopeData)"
                    class="recon-edit-table__col-price_string"
                    @dblclick="onDblclickColumnPrice(scopeData)"
                >
                    {{getColumnNoPriceValue(scopeData)}}
                </div>
                <div v-if="isColumnPriceEdited(scopeData)">
                    <base-text-input
                        ref="baseTextInput"
                        :value="getPriceEditedValue(scopeData)"
                        @onBlur="onBlurPriceEdited($event, scopeData)"
                    />
                </div>
            </div>
        </template>
    </app-table>
</template>

<script>
import AppTable from '@/components/Table2/Table';
import BaseTextInput from '@/components/Base/BaseTextInput';
import BaseCheckboxAccept from '@/components/Base/BaseCheckboxAccept';
import BaseSelect from "@/components/Base/BaseSelect";
/** Компонент таблицы редактирования сверок */
export default {
    name: 'ReconciliationEditTable',
    components: {
        AppTable,
        BaseTextInput,
        BaseCheckboxAccept,
        BaseSelect
    },
    /**
     * Входные данные компонента
     * @property {Array} columns - колонки
     * @property {Array} rows - строки
     * @property {Object} rowsStyles - стили для строк
     * @property {Boolean} isLoading - условие прелоадера
     * @property {Array} currentSelectOptions - строки
     */
    props: {
        columns: {
            type: Array,
            default: () => ([])
        },
        rows: {
            type: Array,
            default: () => ([])
        },
        rowsStyles: {
            type: Object,
            default: () => ({})
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        currentSelectOptions: {
            type: Object,
            default: () => ({})
        },
    },
    /**
     * Данные компонента
     * @property {String} priceEditedColumnProp - редактируемая колонка
     * @property {String} priceEditedRowId - редактируемая строка
     * @property {Object} keysPriceStatus - статусы цен
     * @property {Object} keysPriceProps - ключи для цен
     */
    data: () => ({
        changeOptions: [
            {id: 1, value: 'Один'},
            {id: 2, value: 'Два'},
        ],
        priceEditedColumnProp: '',
        priceEditedRowId: '',
        keysPriceStatus: {
            revised: 'revised',
            notRevised: 'notRevised',
            reviseDifference: 'reviseDifference'
        },
        keysPriceProps: {
            oldValue: 'oldValue',
            reviseValue: 'reviseValue',
            status: 'status'
        },

    }),
    computed: {
        /**
         * Колонки с ценами
         * @returns {Array}
         */
        columnsPrice() {
            const colomnsPrice = this.columns.filter(column => {
                const {isSlot = false} = column;
                return isSlot;
            }).map(column => {
                const {prop = ''} = column;
                return String(prop);
            });
            return colomnsPrice;
        }
    },
    methods: {
        getBoolValue(scopeData = {}){
            console.log(scopeData);
            const value = this.getColumnPriceProp(scopeData, this.keysPriceProps.oldValue);
            return value ? 'Да' : 'Нет';
        },
        /**
         * Ленивая загрузка
         */
        onLazyload() {
            this.$emit('onLazyload');
        },
        /**
         * Выбрать строку
         * @param {Object} checkData - данные выбора
         */
        onCheckRow(checkData = {}) {
            this.$emit('onCheckRow', checkData);
        },
        /**
         * Выбрать все строки
         * @param {Object} checkData - данные выбора
         */
        onCheckRowsAll(isCheck = false) {
            this.$emit('onCheckRowsAll', isCheck);
        },
        /**
         * Получить значение колонки с ценой
         * @param {Object} scopeData - табличные данные
         * @param {String} priceProp - ключ в объекте цены
         * @returns {String}
         */
        getColumnPriceProp(scopeData = {}, priceProp = '') {
            const {column = {}, row = {}} = scopeData;
            const {propGeneral = '', propExtra = ''} = column;
            const columnGeneral = row[propGeneral] || {};
            const price = columnGeneral[propExtra] || {};
            const {oldValue = 0, reviseValue = 0, status = ''} = price;
            const oldValueAdapted = (oldValue !== 0 && oldValue !== null) ? String(oldValue) : '';
            const reviseValueAdapted = (reviseValue !== 0 && reviseValue !== null) ? String(reviseValue) : '';
            const statusAdapted = String(status);
            const priceAdapted = {oldValue: oldValueAdapted, reviseValue: reviseValueAdapted, status: statusAdapted};
            return priceAdapted[priceProp];
        },
        /**
         * Адапатер для колонки с ценой
         * @param {String} value - значение
         * @returns {String}
         */
        adapterColumnPrice(value = '') {
            const valueAdapted = (!isFinite(value) || value === "") ? '' : `${parseInt(value).toLocaleString('ru')} ₽`;
            return valueAdapted;
        },
        /**
         * Получить класс для ячейки
         * @param {Object} scopeData - табличные данные
         * @returns {String}
         */
        getColumnPriceClass(scopeData = {}) {
            const status = this.getColumnPriceProp(scopeData, this.keysPriceProps.status);
            let className = 'recon-edit-table__col-price';
            const {revised = '', notRevised = '', reviseDifference = ''} = this.keysPriceStatus;
            switch (status) {
                case revised:
                    className = `${className}_revised`;
                    break;
                case notRevised:
                    className = `${className}_not-revised`;
                    break;
                case reviseDifference:
                    className = `${className}_revise-diff`;
                    break;
                default:
                    break;
            }
            return className;
        },
        /**
         * Событие двойного клика по основной колонке
         * @param {Object} scopeData - табличные данные
         */
        onDblclickColumnBase(scopeData = {}) {
            const {row = {}, column = {}} = scopeData;
            const {id = ''} = row;
            const {prop = ''} = column;
            this.priceEditedRowId = String(id);
            this.priceEditedColumnProp = String(prop);
            this.$nextTick(() => {
                this.$refs.baseTextInput[0].onFocus();
            });
        },
        /**
         * Событие двойного клика по колонке с ценой
         * @param {Object} scopeData - табличные данные
         */
        onDblclickColumnPrice(scopeData = {}) {
            const {row = {}, column = {}} = scopeData;
            const {id = ''} = row;
            const {prop = ''} = column;
            const status = this.getColumnPriceProp(scopeData, this.keysPriceProps.status);
            const {reviseDifference = ''} = this.keysPriceStatus;
            // eslint-disable-next-line no-debugger
            // debugger;
            if (String(status) !== String(reviseDifference)) {
                this.priceEditedRowId = String(id);
                this.priceEditedColumnProp = String(prop);
                this.$nextTick(() => {
                    this.$refs.baseTextInput[0].onFocus();
                });
            }
        },
        /**
         * Условие для отображения инпута для редактируемой цены
         * @param {Object} scopeData - табличные данные
         * @returns {Boolean}
         */
        isColumnPriceEdited(scopeData = {}) {
            const {row = {}, column = {}} = scopeData;
            const {id = ''} = row;
            const {prop = ''} = column;
            return this.priceEditedRowId === String(id) && this.priceEditedColumnProp === String(prop);
        },
        /**
         * Условие для отображение чекбокса принятия цены
         * @param {Object} scopeData - табличные данные
         * @param {String} priceProp - ключ в объекте цены
         * @returns {Boolean}
         */
        isColumnPriceAccept(scopeData = {}, priceProp = '') {
            const status = this.getColumnPriceProp(scopeData, this.keysPriceProps.status);
            const value = this.getColumnPriceProp(scopeData, priceProp);
            const {reviseDifference = ''} = this.keysPriceStatus;
            return String(status) === String(reviseDifference) && value !== '';
        },
        /**
         * Условие для отображения чекбокса отклонения цены
         * @param {Object} scopeData - табличные данные
         * @param {String} priceProp - ключ в объекте цены
         * @returns {Boolean}
         */
        isColumnPriceNoAccept(scopeData = {}, priceProp = '') {
            const status = this.getColumnPriceProp(scopeData, this.keysPriceProps.status);
            const value = this.getColumnPriceProp(scopeData, priceProp);
            const {reviseDifference = ''} = this.keysPriceStatus;
            return String(status) === String(reviseDifference) && value !== '';
        },
        /**
         * Событие расфокуса инпута редактирования цены
         * @param {Object} selected - объект события
         * @param {Object} scopeData - табличные данные
         */
        onCheckSelect(selected = {}, scopeData = {}) {
            const {id: value = ''} = selected;
            const {row = {}, column = {}} = scopeData;
            const {id = '-1'} = row;
            const {propExtra = '', propGeneral = ''} = column;
            this.priceEditedRowId = '';
            this.priceEditedColumnProp = '';
            const editData = [{
                newValue: String(value),
                rowId: String(id),
                propExtra: String(propExtra),
                propGeneral: String(propGeneral)
            }];
            this.$emit('onEditRow', editData);
        },
        /**
         * Событие расфокуса инпута редактирования цены
         * @param {Object} event - объект события
         * @param {Object} scopeData - табличные данные
         */
        onCheckTax(event = {}, scopeData = {}) {
            const {target = {}} = event;
            const {checked} = target;
            const {row = {}, column = {}} = scopeData;
            const {id = '-1'} = row;
            const {propExtra = '', propGeneral = ''} = column;
            this.priceEditedRowId = '';
            this.priceEditedColumnProp = '';
            const editData = [{
                newValue: checked,
                rowId: String(id),
                propExtra: String(propExtra),
                propGeneral: String(propGeneral)
            }];
            this.$emit('onEditRow', editData);
        },
        /**
         * Событие расфокуса инпута редактирования цены
         * @param {Object} event - объект события
         * @param {Object} scopeData - табличные данные
         */
        onBlurPriceEdited(event = {}, scopeData = {}) {
            const {target = {}} = event;
            const {value = ''} = target;
            const {row = {}, column = {}} = scopeData;
            const {id = '-1'} = row;
            const {propExtra = '', propGeneral = ''} = column;
            this.priceEditedRowId = '';
            this.priceEditedColumnProp = '';
            const editData = [{
                newValue: String(value),
                rowId: String(id),
                propExtra: String(propExtra),
                propGeneral: String(propGeneral)
            }];
            this.$emit('onEditRow', editData);
        },
        /**
         * Событие расфокуса инпута редактирования цены
         * @param {Object} event - объект события
         * @param {Object} scopeData - табличные данные
         */
        onBlurBaseEdited(event = {}, scopeData = {}) {
            const {target = {}} = event;
            const {value = ''} = target;
            const {row = {}, column = {}} = scopeData;
            const {id = '-1'} = row;
            const {prop = ''} = column;
            this.priceEditedRowId = '';
            this.priceEditedColumnProp = '';
            const editData = [{
                newValue: String(value),
                rowId: String(id),
                propExtra: false,
                propGeneral: prop
            }];
            // console.log(editData);
            this.$emit('onEditRow', editData);
        },
        /**
         * Событие принятия / отклонения цены
         * @param {Boolean} isAccept - принять / отклонить
         * @param {Object} scopeData - табличные данные
         */
        onAcceptPrice(isAccept = false, scopeData = {}) {
            const {reviseValue = '', oldValue = ''} = this.keysPriceProps;
            const priceProp = isAccept ? oldValue : reviseValue;
            const {row = {}, column = {}} = scopeData;
            const {id = '-1'} = row;
            const {propExtra = '', propGeneral = ''} = column;
            const value = this.getColumnPriceProp(scopeData, priceProp);
            const editData = [{
                newValue: parseFloat(value),
                rowId: String(id),
                propExtra: String(propExtra),
                propGeneral: String(propGeneral)
            }];
            this.$emit('onEditRow', editData);
        },
        /**
         * Получить значение редактируемой цены
         * @param {Object} scopeData - табличные данные
         * @returns {String}
         */
        getPriceEditedValue(scopeData = {}) {
            const value = this.getColumnPriceProp(scopeData, this.keysPriceProps.oldValue);
            return String(value);
        },
        /**
         * Получить значение колонки не цен
         * @param {Object} scopeData - табличные данные
         * @returns {String}
         */
        getColumnNoPriceValue(scopeData = {}) {
            const value = this.getColumnPriceProp(scopeData, this.keysPriceProps.oldValue);
            return value;
        },
        /**
         * Событие двойного клика по колонке с ценой
         * @param {Object} scopeData - табличные данные
         *
         * scopeData.column.propExtra == 'entity' || scopeData.column.propExtra == 'contractor'
         */
        onDblclickColumnEntity(scopeData = {}) {
            const {row = {}, column = {}} = scopeData;
            const {id = ''} = row;
            const {prop = ''} = column;
            const status = this.getColumnPriceProp(scopeData, this.keysPriceProps.status);
            const {reviseDifference = ''} = this.keysPriceStatus;
            const {propGeneral = '', propExtra = ''} = column;
            // if(propExtra === 'entity' && !row[propGeneral]['contractor'].oldValue){
            //     alert('Не выбран подрядчик');
            //     return false;
            // }
            const getParams = {
                attribute: propExtra,
                value: row[propGeneral]['contractor'].oldValue
            };
            this.$emit('onGetOptions', getParams);
            if (String(status) !== String(reviseDifference)) {
                this.priceEditedRowId = String(id);
                this.priceEditedColumnProp = String(prop);
            }
        },
        /**
         * @param {Object} $event - название колонки по которой был произведен клик в шапке таблицы
         */
        onSort($event) {
            this.$emit('sort', $event)
        },
        /**
         * @param {Object} $event - объект содержащий значения для фильрации
         */
        onFilter($event) {
            this.$emit('filter', $event)
        },
    }
}
</script>

<style lang="scss" scoped>
    .recon-edit-table {
        &__checkbox {
            background: inherit !important;
        }
        &__col-price {
            // padding: 5px;
            display: flex;
            min-width: 130px;
            min-height: 14px;
            cursor: pointer;
            justify-content: center;
            &-container {
                height: 14px;
            }
            // &_revised {
            //     // color: #6EC87A;
            // }
            &_not-revised {
                color: #000000;
            }
            &_revise-diff {
                color: #F84967;
            }
            &-text {
                margin-right: 3px;
            }
            &-accept {
                width: 14px;
                height: 14px;
            }
            &_string {
                min-width: 130px;
                min-height: 14px;
                // padding-bottom: 40px;
                cursor: pointer;
            }
        }
    }
</style>
