<template>
    <div class="recon-summary-item-base">
        <div class="recon-summary-item-base__title">
            {{title}}
        </div>
        <div class="recon-summary-item-base__count">
            {{countFormatted}}
        </div>
        <div class="recon-summary-item-base__sum">
            {{sumFormatted}}
        </div>
    </div>
</template>

<script>
/** Компонент для базового элемента сводных данных */
import MixinReconSummaryItem from '@/mixins/MixinsReconciliation/MixinReconSummaryItem';
export default {
    name: 'ReconSummaryItemBase',
    mixins: [MixinReconSummaryItem]
}
</script>

<style lang="scss" scoped>
    %num {
        font-size: 10px;
        text-align: end;
    }
    .recon-summary-item-base {
        width: 100px;
        &__title {
            margin-bottom: 30px;
        }
        &__count {
            @extend %num;
            margin-bottom: 3px;
        }
        &__sum {
            @extend %num;
        }
    }
</style>