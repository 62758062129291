<template>
    <div 
        class="base-checkbox-accept__circle" 
        :class="classNameCircle"
        @click="onCheck"
    >
        <base-icon 
            :color="iconColor" 
            :icon="iconName" 
            :size="iconSize"
            :class="classNameIcon"
        />
    </div>
</template>

<script>
/** Базовый компонент чекбокса "принять" */
import BaseIcon from "./BaseIcon"
export default {
    name: 'BaseCheckboxAccept',
    components: {BaseIcon},
    /**
     * Входные данные компонента
     * @property {Boolean} isAccept - условие "принять"
     * @property {String} iconSize - размер иконки
     */
    props: {
        isAccept: {
            type: Boolean,
            default: false
        },
        iconSize: {
            type: String,
            default: 'lg'
        }
    },
    computed: {
        /**
         * Название класса для кружочка
         * @returns {String}
         */
        classNameCircle() {
            const classNameCircle = 'base-checkbox-accept__circle';
            return this.isAccept ? `${classNameCircle}_accept` : `${classNameCircle}_no-accept`;
        },
        /**
         * Название класса для иконки
         * @returns {String}
         */
        classNameIcon() {
            const classNameIcon = 'base-checkbox-accept__icon';
            return this.isAccept ? `${classNameIcon}_accept` : `${classNameIcon}_no-accept`;
        },
        /**
         * Название иконки
         * @returns {String}
         */
        iconName() {
            return this.isAccept ? 'icon-tick' : 'icon-close';
        },
        /**
         * Цвет иконки
         * @returns {String}
         */
        iconColor() {
            return this.isAccept ? '#FFFFFF' : '#ADB0BB';
        }
    },
    methods: {
        /**
         * Событие выбора
         */
        onCheck() {
            this.$emit('onCheck', this.isAccept);
        }
    }
}
</script>

<style lang="scss" scoped>
    .base-checkbox-accept {
        &__circle {
            border-radius: 100%;
            width: 20px;
            height: 20px;
            cursor: pointer;
            &_no-accept {
                border: 1px solid #DCE1E8;
            }
            &_accept {
                background-color: #6EC87A;
            }
        }
        &__icon {
            &_no-accept {
                // padding-top: 3px;
                padding-left: 1px;
            }
            &_accept {
                // padding-top: 4px;
                padding-left: 1px;
            }
        }
    }
</style>