export default {
    /**
     * Входные данные компонента
     * @property {String} title - заголовок
     * @property {Number} count - колличество сторон
     * @property {Number} sum - сумма сторон
     */
    props: {
        title: {
            type: String,
            default: ''
        },
        count: {
            type: Number,
            default: ''
        },
        sum: {
            type: Number,
            default: ''
        }
    },
    computed: {
        /**
         * Колличество сторон в нужном формате
         * @returns {String}
         */
        countFormatted() {
            return `${parseInt(this.count).toLocaleString('ru')} ст.`;
        },
        /**
         * Сумма в нужном формате
         * @returns {String}
         */
        sumFormatted() {
            return `${parseInt(this.sum).toLocaleString('ru')} ₽`;
        }
    }
};