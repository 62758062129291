<template>
    <div
        :style="summaryItemStyle" 
        class="recon-summary-item"
    >
        <recon-summary-item-base 
            v-if="summaryItemType === 'base'"
            :title="summaryItemTitle"
            :count="summaryItemCount"
            :sum="summaryItemSum"
        />
        <recon-summary-item-mix 
            v-if="summaryItemType === 'mix'"
            :title="summaryItemTitle"
            :titleColor="summaryItemTitleColor"
            :subTitle="summaryItemSubTitle"
            :emptyMessageFirst="summaryItemEmptyMessageFirst"
            :emptyMessageLast="summaryItemEmptyMessageLast"
            :isEmptyMessage="summaryItemIsEmptyMessage"
            :count="summaryItemCount"
            :sum="summaryItemSum"
            :total="summaryItemTotal"
        />
    </div>
</template>

<script>
/** Компонент для элемента сводных данных */
import ReconSummaryItemBase from './ReconSummaryItemBase.vue';
import ReconSummaryItemMix from './ReconSummaryItemMix.vue';
export default {
    name: 'ReconSummaryItem',
    components: {
        ReconSummaryItemBase,
        ReconSummaryItemMix
    },
    /**
     * Входные данные компонента
     * @property {Object} summaryItem - элемент сводных данных
     */
    props: {
        summaryItem: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        /**
         * Тип (mix|base)
         * @returns {String}
         */
        summaryItemType() {
            const {type = ''} = this.summaryItem;
            return String(type);
        },
        /**
         * Заголовок
         * @returns {String}
         */
        summaryItemTitle() {
            const {title = ''} = this.summaryItem;
            return String(title);
        },
        /**
         * Цвет заголовка
         * @returns {String}
         */
        summaryItemTitleColor() {
            const {titleColor = '#000000'} = this.summaryItem;
            return String(titleColor);
        },
        /**
         * Подзаголовок
         * @returns {String}
         */
        summaryItemSubTitle() {
            const {subTitle = ''} = this.summaryItem;
            return String(subTitle);
        },
        /**
         * Первое сообщение при пустом контенте
         * @returns {String}
         */
        summaryItemEmptyMessageFirst() {
            const {emptyMessageFirst = ''} = this.summaryItem;
            return String(emptyMessageFirst);
        },
        /**
         * Второе сообщение при пустом контенте
         * @returns {String}
         */
        summaryItemEmptyMessageLast() {
            const {emptyMessageLast = ''} = this.summaryItem;
            return String(emptyMessageLast);
        },
        /**
         * Есть ли сообщение для пустого контента
         * @returns {Boolean}
         */
        summaryItemIsEmptyMessage() {
            const {isEmptyMessage = false} = this.summaryItem;
            return Boolean(isEmptyMessage);
        },
        /**
         * Колличество сторон
         * @returns {Number}
         */
        summaryItemCount() {
            const {count = ''} = this.summaryItem;
            return parseInt(count);
        },
        /**
         * Сумма
         * @returns {Number}
         */
        summaryItemSum() {
            const {sum = ''} = this.summaryItem;
            return parseInt(sum);
        },
        /**
         * Всего сторон
         * @returns {Number}
         */
        summaryItemTotal() {
            const {total = 0} = this.summaryItem;
            return parseInt(total);
        },
        /**
         * Стили для элемента сводных
         * @returns {Object}
         */
        summaryItemStyle() {
            const summaryItemStyle = {background: this.summaryItemIsEmptyMessage && this.summaryItemCount === 0 ? '#EAF8F1' : '#F4F5F6'};
            return summaryItemStyle;
        }
    }
}
</script>

<style lang="scss" scoped>
    .recon-summary-item {
        border-radius: 20px;
        padding: 20px;
        margin-right: 15px;
    }
</style>